.ant-layout-header {
  /* -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222; */
  stroke: #eceef0 solid 1px;
  z-index: 10000;
}

body {
  margin: 0;
  overflow: hidden;
}

.logo {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  /* identical to box height, or 92% */
color: #000;
  letter-spacing: 0.2em;
}

.logo2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 36px;
  /* identical to box height, or 92% */
  letter-spacing: 0.495em;
}

.content {
  background: #fff;
  width: 100%;
  height: 92vh;
  overflow-y: auto;
  /* max-height: 100vh; */
  display: block;
  padding: 12px;
}

* ::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

* ::-webkit-scrollbar-track {
  background: #f9fafc;
}

* ::-webkit-scrollbar-thumb {
  background: #c8dff7;
  border-radius: 2%;
  height: 5px;
  width: 5px;
}
.active-circle {
  width: 10px;
  height: 10px;
  text-align: center;
  border-radius: 50%;
  background-color: #19a483;
}

.inactive-circle {
  width: 10px;
  height: 10px;
  text-align: center;
  border-radius: 50%;
  background-color: #e8e7ec;
}

/* .ant-modal .ant-modal-content {
  padding: 16px 0px 0px 24px;
} */

div.ant-upload {
  width: 100%;
}

div.ant-upload input {
  width: 100%;
  cursor: pointer;
  caret-color: transparent;
}

div.ant-card-body {
  padding: 8px !important;
}

.ant-card-body {
  padding: 0px;
}

.ant-page-header-content {
  padding-block-start: 8px;
  /* background-color: #19a483; */
}

.ant-table-sticky-holder {
  top: -15px !important;
}

.ant-table-cell {
  color: #111624 !important;
  color: #4e525a !important;
}

.ant-table-thead > tr > th {
  /* background-color: #f9fafc !important; */
  white-space: nowrap;
  font-weight: 900;
  font-size: 14px;
  /* min-width: 200px;
  width: 220px */
}

.ant-table {
  border: 1px solid #e8e7ec;
}

.page-header {
  padding-left: 10px !important;
}

.ant-table td {
  white-space: nowrap;
  /* width:120; */
}

.ant-table-summary > tr > td {
  background-color: #eceff4 !important;
}


.ant-table-tbody > tr:hover > td {
  background-color: #eceff4 !important;
}

.ant-table-sticky-scroll-bar {
  display: none;
}

/* 
.ant-table-cell-fix-right {
  border-left: 1px solid #eceff4 !important;
}

.ant-table-cell-fix-left:nth-child(3) {
  border-right: 1px solid #eceff4 !important;
} */


.amplify-button[data-variation='primary'] {
  background: #0A7DDC;

}

.ant-table-cell {
  text-align: center;
}





